// Normalize Styles
@import 'node_modules/normalize.css/normalize';

// Import Modules
@import '../_modules/link/link';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
}


// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #FFFFFF;
  color: #000;
  padding: 0.5em 0;
}

.container {
  width: 1024px;
  margin: 0 auto; 
}

h1, h2, h3 {
  font-family: 'Libre Baskerville', serif;
  color: #2E2E46;
}

.header {
  width: 100%;
  margin-top:48px;
}

.landing {
  display:flex;
  flex-wrap:wrap;
  flex-direction:row;
  justify-content:flex-start;
  align-items:stretch;
  h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    span {
      background-image: linear-gradient(-180deg, #FFFFFF 0%, #FFFFFF 60%, #FFC732 60%, #FFC732 100%);
    }
  }
  p {
    font-size: 18px;
    line-height: 28px;
    color: #464C64;
  }
  .head-content, .head-hero {
    width: 50%;
    flex-basis:50%;
  }
  .head-content {
    order: 1;
  }
  .head-hero {
    order: 2;
    #slideshow {
      position: relative;
      div {
        position: absolute; 
        top: 0px; 
        left: 0px; 
        right: 0px; 
        bottom: 0px;
      }
    }
  }
  a.btn {
    background-image: linear-gradient(-133deg, #FFC107 0%, #FF9801 100%);
    display: inline-block;
    padding: 12px 24px;
    border-radius: 6px;
    color: #FFFFFF;
    font-weight: bold;
    margin-top: 18px;
    text-decoration: none;
    &:link {
      color: #FFFFFF;
    }
    &:active {
      color: #FFFFFF;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width:1024px) {
  .container {
    width: 100%;
  }
  .landing {
    flex-wrap:nowrap;
    .head-content, .head-hero {
      width: 100%;
      flex-basis:100%;
    }
  }
}



/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  //CSS
  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  //CSS
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .container {
    width: 100%;
  }
  .header {
    padding: 0 24px ;
    margin-top:24px;
  }
  .landing {
    flex-wrap: nowrap;
    display: block;
    h1 {
      font-size: 36px;
      line-height: 48px;
    }
    p { 
      font-size: 16px;
      line-height: 24px;
    }
    .head-content, .head-hero {
      width: 100%;
      flex-wrap: nowrap;
    }
    .head-content {
      padding: 0 24px;
    }
    .head-hero {
      margin-top: 24px;
    }
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .container {
    width: 100%;
  }
  .header {
    padding: 0 24px ;
    margin-top:24px;
  }
  .landing {
    flex-wrap: nowrap;
    display: block;
    h1 {
      font-size: 36px;
      line-height: 48px;
    }
    p { 
      font-size: 16px;
      line-height: 24px;
    }
    .head-content, .head-hero {
      width: 100%;
      flex-wrap: nowrap;
    }
    .head-content {
      padding: 0 24px;
    }
    .head-hero {
      margin-top: 24px;
    }
  }
  
}
